import { api } from 'src/boot/axios'

export async function loadClients ({ commit, getters, rootGetters }, { clientId, setClient = false } = {}) {
  try {
    commit('setClientsLoading', true)

    const { data } = await api.get('admin/manager/management-group/clients')

    commit('setClients', data)

    if (setClient) {
      clientId ??= getters.getClient?.id
      const currentClient = data.find(client => client.id === clientId)

      commit('setClient', currentClient ?? data[0] ?? null)
    }
  } finally {
    commit('setClientsLoading', false)
  }
}

export async function setClientById ({ commit }, clientId) {
  // const { data } = await api.get(`client/${clientId}`)

  // commit('setClient', data)

  const data = {}

  return data
}
