import {
  DOCTOR_MANAGER,
  CLIENT_MANAGER,
  ADMIN_MANAGER,
  REGISTER_MANAGER,
  LEAD_MANAGER,
  BOLETO_MANAGER,
  CLOSINGS_MANAGER,
  STATEMENT_MANAGER,
  USER_MANAGER,
  COMPANY_MANAGER,
  NATURAL_PERSON_MANAGER,
  COMPANY_CERTIFICATES_MANAGER,
  DOCTOR_CERTIFICATES_MANAGER,
  LICENSE_MANAGER,
  ACCOUNTS_RECEIVABLE_MANAGER,
  // INVOICE_LOGS_MANAGER,
  PROCESSION_MANAGER,
  // PRO_LABORE_MANAGER,
  // DP_MANAGER,
  // TAXES_MANAGER,
  // TRANSACTIONS_MANAGER,
  PLANS_MANAGER,
  COUPONS_MANAGER,
  INDICATIONS_MANAGER,
  // REQUESTS_MANAGER,
  // KNOWLEDGE_BASE_MANAGER,
  CALENDAR_MANAGER,
  REPORTS_MANAGER,
  TERMS_OF_SERVICE_MANAGER,
  NEWS_MANAGER,
  NOTIFICATIONS_MANAGER,
  CASH_BOOK_MANAGER,
  ONBOARDING_MANAGER,
  CASH_BOOK_CLOSINGS_MANAGER,
  CASH_BOOK_UPDATES_MANAGER,
  RECEIPT_MANAGER,
  MONTHLY_OBLIGATIONS_MANAGER,
  LEAD_SIMULATION_MANAGER,
  MANAGEMENT_GROUP_MANAGER
  // ACCREDITATION_MANAGER
} from 'src/constants/users'
import * as dialogRoutes from './dialogRoutes'
import { LAYOUT_CLASSIC, LAYOUT_MODERN, getLayout, setLayout } from 'src/utils/layout'

const newUsersRoutes = [
  {
    path: 'leads',
    name: 'Dashboard.NewUsers.Leads',
    component: () => import('pages/dashboard/new-users/Leads.vue'),
    meta: { role: LEAD_MANAGER }
  },
  {
    path: 'registros',
    name: 'Dashboard.NewUsers.Registers',
    component: () => import('pages/dashboard/new-users/Registers.vue'),
    meta: { role: REGISTER_MANAGER }
  },
  {
    path: 'onboarding',
    name: 'Dashboard.NewUsers.Onboarding',
    component: () => import('pages/dashboard/new-users/Onboarding.vue'),
    meta: { role: ONBOARDING_MANAGER }
  },
  {
    path: 'licenciamentos',
    name: 'Dashboard.NewUsers.License',
    component: () => import('pages/dashboard/new-users/License.vue'),
    meta: { role: LICENSE_MANAGER }
  },
  {
    path: 'leads-simulacoes',
    name: 'Dashboard.NewUsers.LeadsSimulations',
    component: () => import('pages/dashboard/new-users/LeadsSimulations.vue'),
    meta: { role: LEAD_SIMULATION_MANAGER }
  },
  {
    path: 'indicacoes',
    name: 'Dashboard.NewUsers.Indications',
    component: () => import('src/pages/dashboard/new-users/Indications.vue'),
    meta: { role: INDICATIONS_MANAGER }
  },
  {
    path: 'credenciamento',
    name: 'Dashboard.NewUsers.Accreditation',
    component: () => import('pages/dashboard/new-users/Accreditation.vue'),
    meta: { /* role: ACCREDITATION_MANAGER */ }
  }
]

const doctorsRoutes = [
  {
    path: 'geral',
    name: 'Dashboard.Doctors.List',
    component: () => import('pages/dashboard/doctors/Doctors.vue'),
    meta: {
      role: DOCTOR_MANAGER
    }
  },
  {
    path: 'usuarios',
    name: 'Dashboard.Doctors.Users',
    component: () => import('pages/dashboard/doctors/Users.vue'),
    meta: {
      role: USER_MANAGER
    }
  },
  {
    path: 'empresas',
    name: 'Dashboard.Doctors.Companies',
    component: () => import('pages/dashboard/doctors/Companies.vue'),
    meta: {
      role: COMPANY_MANAGER
    }
  },
  {
    path: 'pessoas-fisica',
    name: 'Dashboard.Doctors.NaturalPeople',
    component: () => import('pages/dashboard/doctors/NaturalPeople.vue'),
    meta: {
      role: NATURAL_PERSON_MANAGER
    }
  },
  {
    path: 'certificado-digital/empresas',
    name: 'Dashboard.Doctors.CompanyCertificates',
    component: () => import('pages/dashboard/doctors/CompanyCertificates.vue'),
    meta: {
      role: COMPANY_CERTIFICATES_MANAGER
    }
  },
  {
    path: 'certificado-digital/medicos',
    name: 'Dashboard.Doctors.DoctorCertificates',
    component: () => import('pages/dashboard/doctors/DoctorCertificates.vue'),
    meta: {
      role: DOCTOR_CERTIFICATES_MANAGER
    }
  }
]

const billingRoutes = [
  {
    path: 'contas-a-receber',
    name: 'Dashboard.Billing.AccountsReceivable',
    component: () => import('pages/dashboard/billing/AccountsReceivable.vue'),
    meta: { role: ACCOUNTS_RECEIVABLE_MANAGER }
  },
  {
    path: 'tomadores',
    name: 'Dashboard.Billing.Clients',
    component: () => import('pages/dashboard/billing/Clients.vue'),
    meta: { role: CLIENT_MANAGER }
  },
  // {
  //   path: 'logs',
  //   name: 'Dashboard.Billing.Logs',
  //   component: () => import('pages/dashboard/billing/Logs.vue'),
  //   meta: { role: INVOICE_LOGS_MANAGER }
  // },
  {
    path: 'recibos',
    name: 'Dashboard.Billing.Receipts',
    component: () => import('pages/dashboard/billing/Receipts.vue'),
    meta: { role: RECEIPT_MANAGER }
  }
]

const monthlyRoutes = [
  {
    path: 'acompanhamento',
    name: 'Dashboard.Monthly.Procession',
    component: () => import('pages/dashboard/monthly/Procession.vue'),
    meta: { role: PROCESSION_MANAGER }
  },
  {
    path: 'fechados',
    name: 'Dashboard.Monthly.Closings',
    component: () => import('pages/dashboard/monthly/Closings.vue'),
    meta: { role: CLOSINGS_MANAGER }
  },
  {
    path: 'fechados-livro-caixa',
    name: 'Dashboard.Monthly.CashBookClosings',
    component: () => import('pages/dashboard/monthly/CashBookClosings.vue'),
    meta: { role: CASH_BOOK_CLOSINGS_MANAGER }
  },
  {
    path: 'extratos',
    name: 'Dashboard.Monthly.Statements',
    component: () => import('pages/dashboard/monthly/Statements.vue'),
    meta: { role: STATEMENT_MANAGER }
  },
  // {
  //   path: 'pro-labore',
  //   name: 'Dashboard.Monthly.ProLabore',
  //   component: () => import('pages/dashboard/monthly/ProLabore.vue'),
  //   meta: { role: PRO_LABORE_MANAGER }
  // },
  // {
  //   path: 'dp',
  //   name: 'Dashboard.Monthly.DP',
  //   component: () => import('pages/dashboard/monthly/DP.vue'),
  //   meta: { role: DP_MANAGER }
  // },
  // {
  //   path: 'impostos',
  //   name: 'Dashboard.Monthly.Taxes',
  //   component: () => import('pages/dashboard/monthly/Taxes.vue'),
  //   meta: { role: TAXES_MANAGER }
  // },
  {
    path: 'obrigacoes-mensais',
    name: 'Dashboard.Monthly.MonthlyObligations',
    component: () => import('pages/dashboard/monthly/MonthlyObligations.vue'),
    meta: { role: MONTHLY_OBLIGATIONS_MANAGER }
  }
]

const financialRoutes = [
  // {
  //   path: 'transacoes',
  //   name: 'Dashboard.Financial.Transactions',
  //   component: () => import('pages/dashboard/financial/Transactions.vue'),
  //   meta: { role: TRANSACTIONS_MANAGER }
  // },
  {
    path: 'planos',
    name: 'Dashboard.Financial.Plans',
    component: () => import('pages/dashboard/financial/Plans.vue'),
    meta: { role: PLANS_MANAGER },
    children: [...dialogRoutes.dashboardPlans]
  },
  {
    path: 'cupons',
    name: 'Dashboard.Financial.Coupons',
    component: () => import('pages/dashboard/financial/Coupons.vue'),
    meta: { role: COUPONS_MANAGER }
  }
]

const cashBookRoutes = [
  {
    path: 'geral',
    name: 'Dashboard.CashBook.General',
    component: () => import('pages/dashboard/cashBook/CashBook.vue'),
    meta: { role: CASH_BOOK_MANAGER }
  },
  {
    path: 'atualizacao',
    name: 'Dashboard.CashBook.Updates',
    component: () => import('pages/dashboard/cashBook/CashBookUpdates.vue'),
    meta: { role: CASH_BOOK_UPDATES_MANAGER }
  }
]

// const supportRoutes = [
//   {
//     path: 'solicitacoes',
//     name: 'Dashboard.Support.Requests',
//     component: () => import('pages/dashboard/support/Requests.vue'),
//     meta: { role: REQUESTS_MANAGER }
//   },
//   {
//     path: 'base-de-conhecimento',
//     name: 'Dashboard.Support.KnowledgeBase',
//     component: () => import('pages/dashboard/support/KnowledgeBase.vue'),
//     meta: { role: KNOWLEDGE_BASE_MANAGER }
//   }
// ]

const calendarRoutes = [
  {
    path: 'bookings',
    name: 'Dashboard.Calendar.Bookings',
    component: () => import('pages/dashboard/calendar/Bookings.vue'),
    meta: {
      showIf: !!process.env.BOOKINGS_ADMIN_URL
    }
  },
  {
    path: 'calendario',
    name: 'Dashboard.Calendar.Calendar',
    component: () => import('pages/dashboard/calendar/Calendar.vue'),
    meta: { role: CALENDAR_MANAGER }
  },
  {
    path: 'relatorios',
    name: 'Dashboard.Calendar.Reports',
    component: () => import('pages/dashboard/calendar/Reports.vue'),
    meta: { role: REPORTS_MANAGER }
  }
]

const dutyRoutes = [
  {
    path: 'grupos-gestores',
    name: 'Dashboard.Duty.ManagementGroups',
    component: () => import('pages/dashboard/billing/ManagementGroups.vue'),
    meta: { role: MANAGEMENT_GROUP_MANAGER }
  },
  {
    path: 'gestores-admins',
    name: 'Dashboard.Duty.Managers',
    component: () => import('pages/dashboard/duty/Managers.vue'),
    meta: { MANAGEMENT_GROUP_MANAGER }
  }
]

const advancedRoutes = [
  {
    path: 'analistas',
    name: 'Dashboard.Advanced.Admins',
    component: () => import('pages/dashboard/advanced/Admins.vue'),
    meta: { role: ADMIN_MANAGER }
  },
  {
    path: 'termos-de-servico',
    name: 'Dashboard.Advanced.TermsOfService',
    component: () => import('pages/dashboard/advanced/TermsOfService.vue'),
    meta: { role: TERMS_OF_SERVICE_MANAGER }
  },
  {
    path: 'notificacoes',
    name: 'Dashboard.Advanced.Notifications',
    component: () => import('pages/dashboard/advanced/Notifications.vue'),
    meta: { role: NOTIFICATIONS_MANAGER }
  },
  {
    path: 'novidades',
    name: 'Dashboard.Advanced.News',
    component: () => import('pages/dashboard/advanced/News.vue'),
    meta: { role: NEWS_MANAGER }
  },
  {
    path: 'logs',
    name: 'Dashboard.Advanced.Logs',
    component: () => import('pages/dashboard/advanced/Logs.vue'),
    meta: { masterOnly: true }
  }
]

const appHomeChildren = [...dialogRoutes.appHome]

const appInvoicesChildren = [...dialogRoutes.appInvoices]

const appBoletosChildren = [...dialogRoutes.appBoletos]

const appClientsChildren = [...dialogRoutes.appClients]

const dutyAppRoutes = [
  {
    path: 'credenciamento',
    name: 'App.Duty.Accreditation',
    component: () => import('pages/app/duty/ModernAccreditation.vue'),
    meta: {
      partnersOnly: true
    }
  },
  {
    path: 'credenciamento/convites-internos/:id',
    name: 'App.Duty.Accreditation.AcceptInvite',
    component: () => import('pages/app/duty/ModernAccreditation.vue'),
    meta: {
      partnersOnly: true
    }
  },
  {
    path: 'ofertas',
    name: 'App.Duty.Duty',
    component: () => import('pages/app/duty/ModernDuty.vue'),
    meta: {}
  },
  {
    path: 'escala',
    name: 'App.Duty.Schedule',
    component: () => import('pages/app/duty/ModernDutySchedule.vue'),
    meta: {
      partnersOnly: true
    }
  },
  {
    path: 'minhas-solicitacoes',
    name: 'App.Duty.Requests',
    component: () => import('pages/app/clients/Requests.vue'),
    meta: {
      partnersOnly: true
    }
  }
]

const appCashBookChildren = [...dialogRoutes.appCashBook]

const appReceiptChildren = [...dialogRoutes.appReceipt]

const appStatementsChildren = [...dialogRoutes.appStatements]

const appCashFlowChildren = [...dialogRoutes.appCashFlow]

const dashboardBoletosChildren = [...dialogRoutes.dashboardBoletos]

const importWrapper = (components) => {
  const { classic, modern } = components

  let appLayout = getLayout()

  if (![LAYOUT_CLASSIC, LAYOUT_MODERN].includes(appLayout)) {
    appLayout = LAYOUT_MODERN
    setLayout(appLayout)
  }

  return LAYOUT_MODERN in components && appLayout === LAYOUT_MODERN
    ? modern
    : classic
}

const calendarAppRoutes = [
  {
    path: 'calendario',
    name: 'App.Calendar.Calendar',
    component: () => import('pages/app/calendar/Calendar.vue'),
    meta: {},
    children: [...dialogRoutes.appCalendar]
  },
  {
    path: 'relatorios',
    name: 'App.Calendar.Reports',
    component: () => import('pages/app/calendar/Reports.vue'),
    meta: {},
    children: [...dialogRoutes.appReports]
  },
  {
    path: 'sincronizacao',
    name: 'App.Calendar.Sync',
    component: () => import('pages/app/calendar/Sync.vue'),
    meta: {}
  },
  {
    path: 'reunioes',
    name: 'App.Calendar.Bookings',
    component: () => import('pages/app/calendar/Bookings.vue'),
    meta: {
      showIf: process.env.BOOKINGS_DOCTOR_URL
    }
  }
]

const managementDutyRoutes = [
  {
    path: 'anuncios',
    name: 'Management.Duty.Announcements',
    component: () => import('pages/management/duty/Announcements.vue'),
    meta: {}
  },
  {
    path: 'escala',
    name: 'Management.Duty.Duty',
    component: () => import('pages/management/duty/Duty.vue'),
    meta: {}
  }
]

const managementProfessionalsRoutes = [
  {
    path: 'credenciamento',
    name: 'Management.Professionals.Accreditation',
    component: () => import('pages/management/professionals/Accreditation.vue'),
    meta: {}
  },
  {
    path: 'convite-credenciamento',
    name: 'Management.Professionals.InviteAccreditation',
    component: () => import('pages/management/professionals/InviteAccreditation.vue'),
    meta: {}
  },
  {
    path: 'coordenadores',
    name: 'Management.Professionals.Coordinator',
    component: () => import('pages/management/professionals/Coordinator.vue'),
    meta: {}
  },
  {
    path: 'visualizadores',
    name: 'Management.Professionals.Viewer',
    component: () => import('pages/management/professionals/Viewer.vue'),
    meta: {}
  }
]

const managementSettingsRoutes = [
  {
    path: 'geral',
    name: 'Management.Settings.General',
    component: () => import('pages/management/settings/General.vue'),
    meta: {}
  },
  {
    path: 'unidades',
    name: 'Management.Settings.Units',
    component: () => import('pages/management/settings/Units.vue'),
    meta: {}
  },
  {
    path: 'tags',
    name: 'Management.Settings.Tags',
    component: () => import('pages/management/settings/Tags.vue'),
    meta: {}
  }
]

const routes = [
  {
    path: '/',
    component: () => import('layouts/BlankLayout.vue'),
    meta: {
      addressbarColors: {
        light: '--q-dark',
        dark: '--bg-color'
      }
    },
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('pages/Login.vue'),
        meta: {
          guestOnly: true
        }
      },
      {
        path: 'connect',
        name: 'Connect',
        component: () => import('pages/Connect.vue'),
        meta: {
          guestOnly: true
        }
      },
      {
        path: 'cadastro',
        name: 'Signup',
        component: () => import('pages/Signup.vue')
      },
      {
        path: 'recuperar-senha/:token',
        name: 'RecoverPassword',
        component: () => import('pages/RecoverPassword.vue'),
        meta: {
          guestOnly: true
        }
      },
      {
        path: 'confirmar-email/:token',
        name: 'VerifyEmail',
        component: () => import('pages/VerifyEmail.vue')
      },
      {
        path: 'convite/:token',
        name: 'Invite',
        component: () => import('pages/Invite.vue'),
        meta: {
          guestOnly: true
        }
      },
      {
        path: 'convite-empresa/:token',
        name: 'UserInvite',
        component: () => import('pages/UserInvite.vue')
      },
      {
        path: 'termos-de-servico',
        name: 'TermsOfService',
        component: () => import('pages/TermsOfService.vue')
      },
      {
        path: 'manutencao',
        name: 'Maintenance',
        component: () => import('pages/Maintenance.vue')
      },
      {
        path: 'simulacoes',
        name: 'Simulations',
        component: () => import('pages/app/simulations/Simulations.vue')
      }
    ]
  },
  {
    path: '/app',
    component: () => importWrapper({
      classic: import('layouts/AppLayout.vue'),
      modern: import('layouts/ModernLayout.vue')
    }),
    meta: {
      authOnly: true,
      appOnly: true,
      addressbarColors: {
        [LAYOUT_CLASSIC]: {
          light: '--q-dark',
          dark: '--q-dark'
        },
        [LAYOUT_MODERN]: {
          light: '--q-primary',
          dark: '--q-dark'
        }
      }
    },
    children: [
      {
        path: '',
        name: 'App.Home',
        component: () => importWrapper({
          modern: import('pages/app/home/ModernHome.vue'),
          classic: import('pages/app/home/ClassicHome.vue')
        }),
        children: appHomeChildren
      },
      {
        path: 'empresa',
        name: 'App.Company',
        component: () => import('pages/app/company/ModernCompany.vue'),
        meta: {
          partnersOnly: true
        }
      },
      {
        path: 'faturamento/tomadores',
        name: 'App.Clients',
        component: () => importWrapper({
          modern: import('pages/app/clients/ModernClients.vue'),
          classic: import('pages/app/clients/ClassicClients.vue')
        }),
        children: appClientsChildren
      },
      {
        path: 'docplantao',
        name: 'App.Duty',
        component: () => import('pages/app/duty/Index.vue'),
        children: dutyAppRoutes,
        meta: {
          childRoutes: dutyAppRoutes,
          partnersOnly: true
        }
      },
      {
        path: 'faturamento/contas-a-receber',
        name: 'App.Invoices',
        component: () => importWrapper({
          modern: import('pages/app/invoices/ModernInvoices.vue'),
          classic: import('pages/app/invoices/ClassicInvoices.vue')
        }),
        children: appInvoicesChildren
      },
      {
        path: 'extratos',
        name: 'App.Statements',
        component: () => importWrapper({
          modern: import('pages/app/statements/ModernStatements.vue'),
          classic: import('pages/app/statements/ClassicStatements.vue')
        }),
        children: appStatementsChildren
      },
      {
        path: 'boletos',
        name: 'App.Payments',
        component: () => importWrapper({
          modern: import('pages/app/payments/ModernPayments.vue'),
          classic: import('pages/app/payments/ClassicPayments.vue')
        }),
        children: appBoletosChildren
      },
      {
        path: 'arquivos',
        name: 'App.Files',
        component: () => importWrapper({
          modern: import('pages/app/files/ModernFiles.vue'),
          classic: import('pages/app/files/ClassicFiles.vue')
        })
      },
      {
        path: 'livro-caixa',
        name: 'App.CashBook',
        component: () => importWrapper({
          classic: import('pages/app/cashbook/CashBookDoctor.vue')
        }),
        children: appCashBookChildren,
        meta: {
          partnersOnly: true
        }
      },
      {
        path: 'recibos',
        name: 'App.Receipt',
        component: () => import('pages/app/receipt/ModernReceipt.vue'),
        children: appReceiptChildren
      },
      {
        path: 'agenda',
        name: 'App.Calendar',
        component: () => importWrapper({
          classic: import('pages/app/calendar/Index.vue')
        }),
        children: calendarAppRoutes,
        meta: { childRoutes: calendarAppRoutes }
      },
      {
        path: 'entradas-e-saidas',
        name: 'App.CashFlow',
        component: () => import('pages/app/cashFlow/ModernCashFlow.vue'),
        children: appCashFlowChildren
      },
      {
        path: 'graficos',
        name: 'App.Charts',
        component: () => importWrapper({
          classic: import('pages/app/charts/Charts.vue')
        }),
        meta: {
          partnersOnly: true
        }
      },
      {
        path: 'minha-conta',
        name: 'App.MyAccount',
        component: () => import('pages/common/MyAccount.vue')
      },
      {
        path: 'simulacoes',
        name: 'App.Simulation',
        component: () => import('pages/app/simulations/Simulations.vue')
      }
    ]
  },
  {
    path: '/painel',
    component: () => import('layouts/DashboardLayout.vue'),
    meta: {
      authOnly: true,
      dashboardOnly: true,
      addressbarColors: {
        light: '--q-dark',
        dark: '--q-dark'
      }
    },
    children: [
      {
        path: '',
        name: 'Dashboard.Home',
        component: () => import('pages/dashboard/Home.vue')
      },
      {
        path: 'novos',
        name: 'Dashboard.NewUsers',
        component: () => import('pages/dashboard/new-users/Index.vue'),
        children: newUsersRoutes,
        meta: { childRoutes: newUsersRoutes }
      },
      {
        path: 'medicos',
        name: 'Dashboard.Doctors',
        component: () => import('pages/dashboard/doctors/Index.vue'),
        children: doctorsRoutes,
        meta: { childRoutes: doctorsRoutes }
      },
      {
        path: 'faturamento',
        name: 'Dashboard.Billing',
        component: () => import('pages/dashboard/billing/Index.vue'),
        children: billingRoutes,
        meta: { childRoutes: billingRoutes }
      },
      {
        path: 'mensal',
        name: 'Dashboard.Monthly',
        component: () => import('pages/dashboard/monthly/Index.vue'),
        children: monthlyRoutes,
        meta: { childRoutes: monthlyRoutes }
      },
      {
        path: 'boletos',
        name: 'Dashboard.Boletos',
        component: () => import('pages/dashboard/boletos/Boletos.vue'),
        children: dashboardBoletosChildren,
        meta: { role: BOLETO_MANAGER }
      },
      {
        path: 'financeiro',
        name: 'Dashboard.Financial',
        component: () => import('pages/dashboard/financial/Index.vue'),
        children: financialRoutes,
        meta: { childRoutes: financialRoutes }
      },
      {
        path: 'livro-caixa',
        name: 'Dashboard.CashBook',
        component: () => import('pages/dashboard/cashBook/Index.vue'),
        children: cashBookRoutes,
        meta: { childRoutes: cashBookRoutes }
      },
      // {
      //   path: 'suporte',
      //   name: 'Dashboard.Support',
      //   component: () => import('pages/dashboard/support/Index.vue'),
      //   children: supportRoutes,
      //   meta: { childRoutes: supportRoutes }
      // },
      {
        path: 'agenda',
        name: 'Dashboard.Calendar',
        component: () => import('pages/dashboard/calendar/Index.vue'),
        children: calendarRoutes,
        meta: { childRoutes: calendarRoutes }
      },
      {
        path: 'docplantao',
        name: 'Dashboard.Duty',
        component: () => import('pages/dashboard/duty/Index.vue'),
        children: dutyRoutes,
        meta: { childRoutes: dutyRoutes }
      },
      {
        path: 'avancado',
        name: 'Dashboard.Advanced',
        component: () => import('pages/dashboard/advanced/Index.vue'),
        children: advancedRoutes,
        meta: { childRoutes: advancedRoutes }
      },
      {
        path: 'minha-conta',
        name: 'Dashboard.MyAccount',
        component: () => import('pages/common/MyAccount.vue')
      }
    ]
  },
  {
    path: '/gestao',
    component: () => import('layouts/ManagementLayout.vue'),
    meta: {
      authOnly: true,
      managementOnly: true,
      addressbarColors: {
        light: '--q-dark',
        dark: '--q-dark'
      }
    },
    children: [
      {
        path: '',
        name: 'Management.Home',
        component: () => import('pages/management/Home.vue')
      },
      {
        path: 'plantoes',
        name: 'Management.Duty',
        component: () => import('pages/management/duty/Index.vue'),
        children: managementDutyRoutes,
        meta: { childRoutes: managementDutyRoutes }
      },
      {
        path: 'profissionais',
        name: 'Management.Professionals',
        component: () => import('pages/management/professionals/Index.vue'),
        children: managementProfessionalsRoutes,
        meta: { childRoutes: managementProfessionalsRoutes }
      },
      {
        path: 'notas-fiscais',
        name: 'Management.Invoices',
        component: () => import('pages/management/invoices/Invoices.vue')
      },
      {
        path: 'notificacoes',
        name: 'Management.Notifications',
        component: () => import('pages/management/notifications/Index.vue')
      },
      {
        path: 'qualificacoes',
        name: 'Management.Qualifications',
        component: () => import('pages/management/qualifications/Index.vue')
      },
      {
        path: 'configuracoes',
        name: 'Management.Settings',
        component: () => import('pages/management/settings/Index.vue'),
        children: managementSettingsRoutes,
        meta: { childRoutes: managementSettingsRoutes }
      },
      {
        path: 'minha-conta',
        name: 'Management.MyAccount',
        component: () => import('pages/common/MyAccount.vue')
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
