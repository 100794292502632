export function setClient (state, client) {
  state.client = client
}

export function setClientById (state, clientId) {
  state.client = state.clients.find(client => client.id === clientId)
}

export function setClients (state, clients) {
  state.clients = clients
}

export function clearClients (state) {
  state.client = null
  state.clients = []
  state.clientsLoading = false
}

export function setClientsLoading (state, payload) {
  state.clientsLoading = payload
}
