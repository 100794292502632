import { ADMIN, DOCTOR, PERMISSION_EDIT, MANAGER_ADMIN, MANAGER_COORDINATOR, MANAGER_VIEWER } from 'src/constants/users'

export function getUser (state) {
  return state.user
}

export function getCashBookForm (state) {
  let form = {
    ...state.user.doctor.cash_book_form,
    doctor: {
      ctps: state.user.doctor.ctps
    }
  }

  if (form.work_at_home) {
    form = {
      ...form,
      address: state.user.doctor.address
    }
  }

  return form
}

export function getAvatar (state) {
  return state.user?.avatar_url ?? null
}

export function isAuth (state) {
  return !!state.user && state.user.is_active === true
}

export function isDoctor (state) {
  return isAuth(state) && state.user.type === DOCTOR
}

export function isAdmin (state) {
  return isAuth(state) && state.user.type === ADMIN
}

export function isManager (state) {
  return isAuth(state) &&
    Array.isArray(state.user?.manager_roles) &&
    (
      state.user.manager_roles.includes(MANAGER_VIEWER) ||
      state.user.manager_roles.includes(MANAGER_COORDINATOR) ||
      state.user.manager_roles.includes(MANAGER_ADMIN)
    )
}

export function isManagerAdmin (state) {
  return isAuth(state) && state.user.type === MANAGER_ADMIN
}

export function isManagerCoordinator (state) {
  return isAuth(state) && state.user.type === MANAGER_COORDINATOR
}

export function isManagerViewer (state) {
  return isAuth(state) && state.user.type === MANAGER_VIEWER
}

export function isApp (state) {
  return isDoctor(state)
}

export function isDashboard (state) {
  return isAdmin(state)
}

export function isManagement (state) {
  return isManager(state)
}

export function isMaster (state) {
  return isAuth(state) && state.user.is_master === true
}

export function hasRole (state) {
  return (role, permission = PERMISSION_EDIT) => {
    return isMaster(state) || !!(
      isAdmin(state) &&
      (state.user.permissions ?? []).find(_role => _role.name === role && _role[permission])
    )
  }
}

export function hasTwoFactor (state) {
  return isAuth(state) && state.user.has_two_factor === true
}

export function hasFlag (state) {
  return (flag) => {
    return (state.user?.flags ?? []).some(_flag => _flag.description === flag)
  }
}
