export const ACTIVE = 'Ativo'
export const EXPIRED = 'Expirado'
export const INACTIVE = 'Inativo'
export const PENDING = 'Pendente'

export const ADMIN = 'Administrador'
export const DOCTOR = 'Médico'
export const MANAGER_VIEWER = 'Visualizador'
export const MANAGER_COORDINATOR = 'Coordenador'
export const MANAGER_ADMIN = 'Gestor Administrador'

export const PARTNER = 'Sócio'
export const EMPLOYEE = 'Funcionário'
export const THIRD = 'Terceiro'
export const doctorRoles = [PARTNER, EMPLOYEE]

export const doctorRoleOptions = [PARTNER, EMPLOYEE]

export const statusOptions = [
  ACTIVE,
  EXPIRED,
  INACTIVE,
  PENDING
]

export const statusColors = {
  [ACTIVE]: 'positive',
  [EXPIRED]: 'purple',
  [INACTIVE]: 'negative',
  [PENDING]: 'orange'
}

export const USER_STATUS_PROPS = {
  [ACTIVE]: { color: 'positive', label: ACTIVE },
  [EXPIRED]: { color: 'purple', label: 'Convite Expirado' },
  [INACTIVE]: { color: 'negative', label: INACTIVE },
  [PENDING]: { color: 'orange', label: 'Convite Pendente' }
}

export const userTypes = [ADMIN, DOCTOR]

export const LOGIN_TYPE_MICROSOFT = 'microsoft'

export const LOGIN_TYPE_PASSWORD = 'password'

export const PARTNER_MIN_AGE = 18
export const PARTNER_MAX_AGE = 120

export const REGIME_FULL_COMMUNITY_PROPERTY = 'Comunhão Parcial de Bens'
export const REGIME_PARTIAL_COMMUNITY_PROPERTY = 'Comunhão Universal de Bens'
export const REGIME_CONVENTIONAL_SEPARATION_OF_PROPERTY = 'Separação Convencional de Bens'
export const REGIME_MANDATORY_SEPARATION_OF_PROPERTY = 'Separação Obrigatória de Bens'
export const REGIME_FINAL_PARTICIPATION_IN_ACQUISITIONS = 'Participação Final nos Aquestos'

export const MATRIMONIAL_REGIMES = [
  REGIME_FULL_COMMUNITY_PROPERTY,
  REGIME_PARTIAL_COMMUNITY_PROPERTY,
  REGIME_CONVENTIONAL_SEPARATION_OF_PROPERTY,
  REGIME_MANDATORY_SEPARATION_OF_PROPERTY,
  REGIME_FINAL_PARTICIPATION_IN_ACQUISITIONS
]

// New Users
export const LEAD_MANAGER = 'lead-manager'
export const REGISTER_MANAGER = 'register-manager'
export const ONBOARDING_MANAGER = 'onboarding-manager'
export const LICENSE_MANAGER = 'licenses-manager'
export const FORCE_LEAD_MANAGER = 'force-lead-to-register'
export const LEAD_SIMULATION_MANAGER = 'lead-simulation-manager'

// Doctors
export const DOCTOR_MANAGER = 'doctor-manager'
export const USER_MANAGER = 'user-manager'
export const COMPANY_MANAGER = 'company-manager'
export const NATURAL_PERSON_MANAGER = 'natural-person-manager'
export const COMPANY_CERTIFICATES_MANAGER = 'company-certificates-manager'
export const DOCTOR_CERTIFICATES_MANAGER = 'doctor-certificates-manager'
export const UPDATE_INVOICE_ISSUE_STATUS = 'update-invoice-issue-status'
export const USER_EXPORT_MANAGER = 'users-export'

// Billing
export const ACCOUNTS_RECEIVABLE_MANAGER = 'accounts-receivable-manager'
export const CLIENT_MANAGER = 'client-manager'
export const INVOICE_LOGS_MANAGER = 'invoice-logs-manager'
export const RECEIPT_MANAGER = 'receipt-manager'

// Monthly
export const PROCESSION_MANAGER = 'procession-manager'
export const CLOSINGS_MANAGER = 'closings-manager'
export const CASH_BOOK_CLOSINGS_MANAGER = 'cash-book-closings-manager'
export const STATEMENT_MANAGER = 'statement-manager'
export const PRO_LABORE_MANAGER = 'pro-labore-manager'
export const DP_MANAGER = 'dp-manager'
export const TAXES_MANAGER = 'taxes-manager'
export const MONTHLY_OBLIGATIONS_MANAGER = 'monthly-obligations-manager'

// Boleto
export const BOLETO_MANAGER = 'boleto-manager'

// Financial
export const TRANSACTIONS_MANAGER = 'transactions-manager'
export const PLANS_MANAGER = 'plans-manager'
export const COUPONS_MANAGER = 'coupons-manager'
export const INDICATIONS_MANAGER = 'indications-manager'

// Relationship
export const REQUESTS_MANAGER = 'requests-manager'
export const KNOWLEDGE_BASE_MANAGER = 'knowledge-base-manager'

// Calendar
export const CALENDAR_MANAGER = 'calendar-manager'
export const REPORTS_MANAGER = 'reports-manager'

// Advanced
export const ADMIN_MANAGER = 'admin-manager'
export const CAN_CHANGE_STATUS_ADMIN = 'can-change-status-admin'
export const TERMS_OF_SERVICE_MANAGER = 'terms-of-service-manager'
export const NEWS_MANAGER = 'newsletter-manager'
export const NOTIFICATIONS_MANAGER = 'notifications-manager'
// export const LOGS_MANAGER = 'logs-manager'

// Cash Book
export const CASH_BOOK_MANAGER = 'cash-book-manager'
export const CASH_BOOK_UPDATES_MANAGER = 'cash-book-updates-manager'

// Duty
export const MANAGEMENT_GROUP_MANAGER = 'management-group-manager'
// export const ACCREDITATION_MANAGER = 'accreditation-manager' // TODO: Ajustar permissão do módulo de Credenciamento quando ligar com backend

export const groupedRoleTypes = [
  {
    label: 'Novos',
    icon: 'assignment_ind',
    roles: [
      { label: 'Leads', value: LEAD_MANAGER },
      { label: 'Registros', value: REGISTER_MANAGER },
      { label: 'Onboarding', value: ONBOARDING_MANAGER },
      { label: 'Licenciamento', value: LICENSE_MANAGER },
      { label: 'Leads - Simulação', value: LEAD_SIMULATION_MANAGER },
      { label: 'Indicações', value: INDICATIONS_MANAGER },
      { label: 'Forçar Lead para Registro', value: FORCE_LEAD_MANAGER, onlyEdit: true }
    ]
  },
  {
    label: 'Médicos',
    icon: 'medical_information',
    roles: [
      { label: 'Geral', value: DOCTOR_MANAGER },
      { label: 'Usuários', value: USER_MANAGER },
      { label: 'Empresas', value: COMPANY_MANAGER },
      { label: 'Pessoas Físicas', value: NATURAL_PERSON_MANAGER },
      { label: 'Habilitar / Desabilitar Emissão de NF', value: UPDATE_INVOICE_ISSUE_STATUS, onlyEdit: true },
      { label: 'Habilitar exportação de planilhas de Usuários', value: USER_EXPORT_MANAGER, onlyEdit: true },
      { label: 'Certificado Digital (Empresa)', value: COMPANY_CERTIFICATES_MANAGER },
      { label: 'Certificado Digital (Médico)', value: DOCTOR_CERTIFICATES_MANAGER }
    ]
  },
  {
    label: 'Faturamento',
    icon: 'attach_money',
    roles: [
      { label: 'Contas a Receber', value: ACCOUNTS_RECEIVABLE_MANAGER },
      { label: 'Tomadores', value: CLIENT_MANAGER },
      { label: 'Recibos', value: RECEIPT_MANAGER }
      // { label: 'Logs', value: INVOICE_LOGS_MANAGER }
    ]
  },
  {
    label: 'Mensal',
    icon: 'o_verified',
    roles: [
      { label: 'Acompanhamento', value: PROCESSION_MANAGER },
      { label: 'Fechados (PJ)', value: CLOSINGS_MANAGER },
      { label: 'Fechados (Livro Caixa)', value: CASH_BOOK_CLOSINGS_MANAGER },
      { label: 'Extratos', value: STATEMENT_MANAGER },
      { label: 'Obrigações Mensais', value: MONTHLY_OBLIGATIONS_MANAGER }
      // { label: 'Pró-Labore', value: PRO_LABORE_MANAGER },
      // { label: 'DP', value: DP_MANAGER },
      // { label: 'Impostos', value: TAXES_MANAGER }
    ]
  },
  {
    label: 'Boletos',
    icon: 'request_quote',
    roles: [
      { label: 'Boletos', value: BOLETO_MANAGER }
    ]
  },
  {
    label: 'Financeiro',
    icon: 'account_balance',
    roles: [
      { label: 'Transações', value: TRANSACTIONS_MANAGER },
      { label: 'Planos', value: PLANS_MANAGER },
      { label: 'Cupons', value: COUPONS_MANAGER }
    ]
  },
  {
    label: 'Livro Caixa',
    icon: 'menu_book',
    roles: [
      { label: 'Geral', value: CASH_BOOK_MANAGER },
      { label: 'Atualizações', value: CASH_BOOK_UPDATES_MANAGER }
    ]
  },
  // {
  //   label: 'Suporte',
  //   roles: [
  //     { label: 'Solicitações', value: REQUESTS_MANAGER },
  //     { label: 'Base de Conhecimento', value: KNOWLEDGE_BASE_MANAGER }
  //   ]
  // },
  {
    label: 'Agenda',
    icon: 'event',
    roles: [
      { label: 'Calendário', value: CALENDAR_MANAGER },
      { label: 'Relatórios', value: REPORTS_MANAGER }
    ]
  },
  {
    label: 'DocPlantão',
    icon: 'medical_services',
    roles: [
      { label: 'Gestores', value: MANAGEMENT_GROUP_MANAGER }
      // { label: 'Credenciamento', value: ACCREDITATION_MANAGER }
    ]
  },
  {
    label: 'Avançado',
    icon: 'construction',
    roles: [
      { label: 'Analistas', value: ADMIN_MANAGER },
      { label: 'Termos de Serviços', value: TERMS_OF_SERVICE_MANAGER },
      { label: 'Notificações', value: NOTIFICATIONS_MANAGER },
      { label: 'Novidades', value: NEWS_MANAGER },
      { label: 'Ativar/Inativar Analistas', value: CAN_CHANGE_STATUS_ADMIN, onlyEdit: true }
      // { label: 'Logs', value: LOGS_MANAGER }
    ]
  }
]

export const roleTypes = groupedRoleTypes.flatMap(group => {
  return group.roles.map(role => {
    return {
      label: group.label !== role.label
        ? `${group.label} - ${role.label}`
        : role.label,
      value: role.value
    }
  })
})

export const PERMISSION_VIEW = 'view'
export const PERMISSION_EDIT = 'edit'
export const PERMISSION_DELETE = 'delete'
export const permissionTypes = [
  { label: 'Visualizar', value: PERMISSION_VIEW },
  { label: 'Criar/Editar', value: PERMISSION_EDIT },
  { label: 'Excluir', value: PERMISSION_DELETE }
]

export const DEFAULT_CRM_CREDENTIALS = {
  login: '',
  password: '',
  state: ''
}

export const DEFAULT_ADDRESS = {
  cep: '',
  city: '',
  state: '',
  neighborhood: '',
  street: '',
  number: ''
}

export const DEFAULT_DOCTOR = {
  cpf: '',
  rg: '',
  ctps: '',
  birth_city: '',
  college: '',
  birth_date: '',
  marital_status: '',
  address: { ...DEFAULT_ADDRESS },
  is_resident_doctor: false,
  serve_armed_forces: false,
  permanent_employee: false,
  has_fies: false,
  is_certificate_expired: false,
  nf_requirements: ''
}

export const DEFAULT_USER = {
  name: '',
  email: '',
  notifications_email: ''
}

export const DEFAULT_DOCTOR_USER = {
  ...DEFAULT_USER,
  role: PARTNER,
  company_id: null,
  doctor: { ...DEFAULT_DOCTOR }
}

export const DEFAULT_EMPLOYEE_USER = {
  ...DEFAULT_USER,
  role: EMPLOYEE
}

export const USERS_IDS_WITH_MODERN_LAYOUT_ENABLED = [
  6, // João Vasconcellos
  15, // ADRIANA FARDIM BUSATTO
  118, // MILTON AGRIZZI DAVID
  133, // HENRIQUE DE MATOS BALMANT BERBERT
  220, // CAROLINA PADUA ROCHA DE SOUZA
  591, // HENRIQUE REUTER DO NASCIMENTO
  732, // ISADORA CUNHA LEAO FIGUEREDO
  903, // VICTOR KIEFER FERREIRA
  968, // MARCO ANTÔNIO SANTOS OLIVEIRA
  1266, // Wagner Calvi
  1295, // PIETRO VIDEIRA TANURE
  1488, // Isadora Mund
  1590 // Karine Reis de Miranda
]

export const DEFAULT_THIRD_MEMBER = {
  name: '',
  cpf: '',
  email: '',
  phone: ''
}
