export function getClient (state) {
  return state.client
}

export function getClients (state) {
  return state.clients ?? []
}

export function getClientsLoading (state) {
  return state.clientsLoading
}
