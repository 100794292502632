import { store } from 'quasar/wrappers'
import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

import auth from './auth'
import banks from './banks'
import client from './client'
import company from './company'
import globals from './globals'
import ibge from './ibge'
import settings from './settings'
import tablePreferences from './tablePreferences'
import countries from './countries'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

const persistedState = new VuexPersistence({
  reducer: (state) => ({
    auth: {
      user: state.auth.user
    },
    banks: {
      banks: state.banks.banks
    },
    client: {
      client: state.client.client,
      clients: state.client.clients
    },
    company: {
      company: state.company.company,
      companies: state.company.companies
    },
    ibge: {
      states: state.ibge.states,
      cities: state.ibge.cities
    },
    settings: {
      balance_type: state.settings.balance_type,
      only_paid_received: state.settings.only_paid_received,
      show_balance_value: state.settings.show_balance_value,
      only_authorized_invoices: state.settings.only_authorized_invoices
    },
    tablePreferences: {
      hideFields: state.tablePreferences.hideFields,
      actionMode: state.tablePreferences.actionMode,
      defaultActionMode: state.tablePreferences.defaultActionMode
    },
    countries: {
      countries: state.countries.countries
    }
  }),
  key: process.env.STORAGE_KEY
})

export default store(function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      auth,
      banks,
      client,
      company,
      globals,
      ibge,
      settings,
      tablePreferences,
      countries
    },
    plugins: [
      persistedState.plugin
    ],

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING
  })

  return Store
})
