import { Notify } from 'quasar'
import { route } from 'quasar/wrappers'
import { setAddressbarColorFromRoute } from 'src/boot/addressbar-color'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'
import { PERMISSION_VIEW } from 'src/constants/users'
import { computed } from 'vue'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

let flag = false

export default route(function ({ store }) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: (to, from) => {
      return !to.meta.dialogComponent && !from.meta.dialogComponent
        ? { left: 0, top: 0 }
        : {}
    },
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })

  Router.afterEach((to, from) => {
    store.commit('globals/setLoading', false)

    setAddressbarColorFromRoute(to)
  })

  Router.beforeEach(async (to, from, next) => {
    const { empresa: companyId } = to.query
    if (!isNaN(companyId)) {
      const company = await store.dispatch('company/setCompanyById', parseInt(companyId))
      if (company) {
        delete to.query.empresa
        return next(to)
      }
    }
    next()
  })

  Router.beforeEach((to, from, next) => {
    if (from.name) store.commit('globals/setLoading', true)

    const user = store.getters['auth/getUser']

    if (!user) {
      if (to.matched.some(route => route.meta.authOnly)) {
        if (flag) return
        flag = true
        Notify.create({
          type: 'warning',
          message: 'Faça o login para acessar esta página.'
        })
        return next({
          name: 'Login',
          query: {
            redirect: to.path
          }
        })
      }
      return next()
    }

    const defaultRoute = computed(() => {
      if (store.getters['auth/isDashboard']) {
        return 'Dashboard'
      } else if (store.getters['auth/isManagement']) {
        return 'Management'
      } else {
        return 'App'
      }
    })

    const currentRoute = computed(() => store.getters['auth/getUser'].current_route || defaultRoute.value)

    const isDashboard = store.getters['auth/isDashboard']
    const isApp = store.getters['auth/isApp']
    const isManagement = store.getters['auth/isManagement']

    const isPartner = store.getters['company/isPartner']
    const isEmployee = store.getters['company/isEmployee']

    const hasMeta = (opt) => to.matched.some(route => route.meta[opt])

    if (
      (hasMeta('dashboardOnly') && !isDashboard) ||
      (hasMeta('appOnly') && !isApp) ||
      (hasMeta('managementOnly') && !isManagement) ||
      (hasMeta('partnersOnly') && !isPartner) ||
      (hasMeta('isEmployee') && !isEmployee)
    ) {
      Notify.create({
        type: 'warning',
        message: 'Você não possui permissões suficientes para acessar esta página.'
      })
      return next({ name: 'Login' })
    }

    const isMaster = store.getters['auth/isMaster']

    if (!isMaster && hasMeta('masterOnly')) {
      Notify.create({
        type: 'alert',
        message: 'Sua conta atual não possui permissão para visualizar esta página.'
      })
      return next({ name: 'Login' })
    }

    if (hasMeta('guestOnly')) {
      return next({ name: `${currentRoute.value}.Home` })
    }

    const routeRole = to.matched.find(route => route.meta.role)

    if (routeRole && !(store.getters['auth/hasRole'](routeRole.meta.role, PERMISSION_VIEW))) {
      Notify.create({
        type: 'alert',
        message: 'Você não possui acesso a este módulo.'
      })
      return next({ name: 'Dashboard.Home' })
    }

    return next()
  })

  Router.beforeEach((to, from, next) => {
    const lastMatched = to.matched[to.matched.length - 1]
    if (lastMatched.meta.childRoutes) {
      const firstAllowed = lastMatched.meta.childRoutes.find((child) => {
        const { role, showIf } = child.meta
        return (!role || store.getters['auth/hasRole'](role, PERMISSION_VIEW)) &&
          (typeof showIf === 'undefined' || (typeof showIf === 'function' ? showIf(store) : !!showIf))
      })
      return next(firstAllowed ?? { path: '/' })
    }
    return next()
  })

  return Router
})
